<template>
  <div :style="`background: ${getBackground} !important`">
    <div
      class="wrapper layout-wrapper h-100"
      :class="{
        'hide-vertical-scroll': hideScrollbar,
        disable: ongoingOnboarding && !isEmpty(onboarding),
      }"
      :style="prfBackgroundImageStyle"
    >
      <ClientOnly>
        <SideMenu />
      </ClientOnly>
      <ClientOnly>
        <CustomizationThemeMenu
          :is-close="isCloseThemeMenu"
          @close="isCloseThemeMenu = false"
          @showtoast="showingSavedToast"
          @applied="appliedTemp"
        />
      </ClientOnly>

      <ClientOnly>
        <customize-top-bar v-if="isStylize" @showtoast="showingSavedToast" @applied="appliedTemp" />
      </ClientOnly>
      <ClientOnly>
        <nav-bar v-if="!isStylize && !isChatPage" class="fixed-mobile" />
      </ClientOnly>

      <BackgroundImageHandler v-if="isProfile || isStylize" @save="isCloseThemeMenu = true" />
      <BackgroundOverlayHandler v-if="isProfile || isStylize" @save="isCloseThemeMenu = true" />
      <div
        class="main flex-grow-1 d-flex position-relative justify-content-center"
        :class="{ 'set-margin': isChatPage }"
        :style="` justify-content: ${getContentStyle};`"
      >
        <ClientOnly>
          <customize-apply-section
            v-if="isStylize"
            @showtoast="showingSavedToast"
            @createdtoast="showingToast"
            @applied="appliedTemp"
          />
        </ClientOnly>

        <ion-content
          :scroll-events="true"
          @ionScroll="scrollCb"
          :class="[
            'layout-scroll-area d-flex',
            {
              'hide-vertical-scroll': hideScrollbar,
              'stylize-layout': isStylize,
              'with-keyboard': keyboardOpen,
              'char-layout': isCharacterProfile,
              'profile-layout': isProfile,
            },
            profileClass,
          ]"
          :style="{ '--background-url': `url(${animation})`, '--background': getBackgroundContent }"
        >
          <div>
            <ion-refresher mode="md" slot="fixed" @ionRefresh="handleRefresh($event)">
              <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <div v-if="refresher">
              <div
                class="layout w-100 position-relative"
                :class="{
                  isXmas,
                  snow,
                  'chat-page': isChatPage,
                  'stylize-layout': isStylize,
                  lock: isRolechatPage,
                }"
              >
                <div
                  id="main-content"
                  class="d-flex flex-column flex-grow-1"
                  :class="{
                    'color-changed': isChatPage,
                    'customize-margin': isStylize && !isEmpty(getTemplate),
                    'customize-not-applied-margin': isStylize && isEmpty(getTemplate),
                    'customize-try-on-margin':
                      isStylize && get($route.query, 'tempId') && !isEmpty(get(customizeChar, 'template_applied')),
                    'h-100': isChatPage,
                  }"
                >
                  <div v-if="!get(user, 'is_email_verified', true)">
                    <router-link to="/account/settings" class="sticky-warning">
                      <ion-card class="m-0 warning p-2 sticky-warning">
                        <div class="d-flex align-items-center justify-content-center text-color" style="height: 35px">
                          <ion-icon class="mr-2" style="font-size: 18px !important" :icon="warningOutline" />
                          <strong style="font-size: 12px !important">
                            Verify your email to get started with publishing your content</strong
                          >
                        </div>
                      </ion-card>
                    </router-link>
                  </div>

                  <div
                    class="theme-edit-btn"
                    v-if="['character-stylize', 'character-draft-stylize'].includes(defaultPageRoute)"
                  >
                    <ion-button class="clickable" @click="handleCustomEvent">
                      <inline-svg src="/palette.svg" class="mr-1 edit-theme-icon" />
                      Edit Theme
                    </ion-button>
                  </div>
                  <div v-if="!userHasCharacters">
                    <router-link :to="{ name: 'character-creator' }" class="sticky-warning">
                      <ion-card class="m-0 bg-secondary sticky-warning">
                        <div class="d-flex align-items-center justify-content-center text-color" style="height: 35px">
                          <ion-icon class="mr-1" style="font-size: 18px !important" :icon="warningOutline" />
                          <strong> Create character to unlock more features.</strong>
                        </div>
                      </ion-card>
                    </router-link>
                  </div>

                  <slot />
                  <div v-if="isStylize" style="width: 100%; height: 50px" />
                </div>
              </div>
            </div>
          </div>
        </ion-content>

        <!-- <ClientOnly>
        <customize-settings-bar
          v-if="isStylize"
          :id="String($route.params.id)"
          @showtoast="showingSavedToast"
          @createdtoast="showingToast"
        />
      </ClientOnly> -->
      </div>
      <ClientOnly>
        <bottom-bar
          :class="{
            'hide-placeholder': isStylize || keyboardWillOpen,
          }"
        />
      </ClientOnly>
      <ClientOnly>
        <customize-bottom-bar v-if="isStylize" />
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavBar from '@/shared/components/nav-bar/NavBar.vue';
import SideMenu from '@/shared/components/SideMenu.vue';
import BottomBar from '@/shared/components/bottom-bar.vue';
import CustomizeTopBar from '@/shared/components/customize-top-bar.vue';
import CustomizeApplySection from '@/shared/components/customize-apply-section.vue';
import CustomizeBottomBar from '@/shared/components/customize-bottom-bar.vue';
import CustomizeSettingsBar from '@/shared/components/customize-settings-bar.vue';
import CustomizeBackgroundImage from '~/shared/components/customize-background-image.vue';
import { featureFlags } from '@/shared/config/feature-flags';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { textColorChanged } from '@/shared/utils/textConverter';
import { warningOutline } from 'ionicons/icons';
import { onboardingStore } from '@/shared/pinia-store/onboarding';
import { keyboard } from '~/apps/mobile/native/keyboard';
import CustomizationThemeMenu from '~/shared/components/CustomizationThemeMenu.vue';
import { menuController } from '@ionic/vue';
import BackgroundImageHandler from '~/shared/components/BackgroundImageHandler.vue';
import { useBackgroundImage } from '~/composables/useBackgroundImage';
import { profileStore } from '../pinia-store/profile';
import BackgroundOverlayHandler from '~/shared/components/BackgroundOverlayHandler.vue';
const { customizeCharacter, currentTemplate, template } = characterStylizeStore();
const { ongoingOnboarding, onboarding } = onboardingStore();
const {
  updateCreatedToast,
  updateSavedToast,
  updateTempApp,
  isSavedToast,
  isCreatedToast,
  isAuthenticated,
  updateScrollHandleAtBottom,
  userHasCharacters,
  user,
  userTryOnAnimation,
  updateProfileTryonImage,
  updateProfileTryOnBgImage,
} = authStore();
const { prfBackgroundImageStyle } = useBackgroundImage();
const { isCharacterProfile, isCustomizeProfile } = useCharPageColors();
const { currentProfile } = profileStore();
const { width } = useWindowSize();
const isApplied = ref(false);
const refresher = ref(true);
const isCloseThemeMenu = ref(false);
const handleCustomEvent = async () => {
  await menuController.open('end');
};
const handleRefresh = (event: any) => {
  refresher.value = false;
  setTimeout(() => {
    refresher.value = true;
    event.target.complete();
  }, 1000);
};

const isXmas = featureFlags.isXmas;
const { snow, dark: isDark, keyboardOpen, keyboardWillOpen } = mainStore();

const defaultTextColor = computed(() => {
  const background = getBackground.value?.replace(' !important', '');
  const defaultClr = isDark.value ? '#FFFFFF' : '#214163';

  if (!background) return defaultClr;

  return textColorChanged(background);
});

const profileClass = computed(() => {
  return isCharacterProfile.value || isCustomizeProfile.value ? 'profile-override' : '';
});

const defaultPageRoute = computed(() => {
  try {
    const router = useRouter();
    return String(router.currentRoute.value.name);
  } catch (error) {
    return '';
  }
});
const animation = computed(() => {
  if (featureFlags.backgroundOverlay) {
    if (!isEmpty(userTryOnAnimation.value)) return get(userTryOnAnimation.value, 'image');
    else {
      return isProfile.value
        ? get(currentProfile.value, 'customize_profile.background_overlay.image')
        : get(currentTemplate.value, 'data.background_overlay.image');
    }
  }
});

const isTabletScreen = computed(() => {
  return width.value >= 1024 && width.value < 1365;
});

const getContentStyle = computed(() => {
  return isStylize.value && isTabletScreen.value ? 'normal' : 'center ';
});

const customizeChar = computed(() => {
  return customizeCharacter.value;
});

const getBackground = computed(() => {
  const defaultClr = isDark.value ? '#010123' : '#F9F9FF';
  const isCharacterPage = isCharacterProfile.value || isCustomizeProfile.value;
  if (isCharacterPage) {
    const templateApplied = get(customizeChar.value, 'template_applied');
    if (
      isEmpty(templateApplied) &&
      ['character-profile-new', 'character-profile-draft-new'].includes(defaultPageRoute.value)
    ) {
      return defaultClr;
    } else {
      return get(currentTemplate.value, 'data.theme.backgrounds.page', defaultClr);
    }
  }

  return defaultClr;
});

const getBackgroundContent = computed(() => {
  return isStylize.value || isProfile.value ? 'none' : '';
});
const getTemplate = computed(() => {
  return template.value;
});

const showingToast = (isToast: boolean) => {
  updateCreatedToast(isToast);
  setTimeout(() => {
    updateCreatedToast(false);
  }, 1000);
};

const showingSavedToast = (saved: boolean) => {
  updateSavedToast(saved);
  setTimeout(() => {
    updateSavedToast(false);
  }, 2600);
};

const appliedTemp = () => {
  isApplied.value = true;
  updateTempApp(isApplied.value);
  setTimeout(() => {
    updateTempApp(false);
  }, 500);
};

const isStylize = computed(() => {
  return defaultPageRoute.value === 'character-stylize' || defaultPageRoute.value === 'character-draft-stylize';
});

const isProfile = computed(() => {
  return defaultPageRoute.value === 'profile';
});
const isChatPage = computed(() => {
  return defaultPageRoute.value === 'social-space-chatroom';
});

const isRolechatPage = computed(() => {
  return defaultPageRoute.value === 'rolechat-room';
});

const hideScrollbar = computed(() => {
  return isChatPage.value;
});
const overlaymode = computed(() => {
  if (isProfile.value) {
    return get(currentProfile.value, 'customize_profile.background_overlay.extra.bgRepeat') ? 'repeat' : 'no-repeat';
  } else {
    return get(currentTemplate.value, 'data.background_overlay.extra.bgRepeat') ? 'repeat' : 'no-repeat';
  }
});

const scrollCb = (e: any) => {
  // try {
  //   const target = e.target;
  //   const route = useRoute();
  //   if (target && route.name === 'quizzes') {
  //     const scrolloffset = 20;

  //     if (
  //       e.target.scrollTop >=
  //       target.scrollHeight - (target.clientHeight + scrolloffset)
  //     ) {
  //       updateScrollHandleAtBottom(true);
  //     } else {
  //       updateScrollHandleAtBottom(false);
  //     }
  //   }
  // } catch (error) {}
  try {
    const { initHeight, keyboardOpen } = mainStore();

    if (!keyboardOpen.value) return;

    keyboard.hide();

    const doc = document.documentElement;

    doc.style.setProperty('--doc-height', `${initHeight.value}px`);
  } catch (error) {}
};

onMounted(() => {
  if (isSavedToast.value) {
    updateSavedToast(false);
  } else if (isCreatedToast.value) {
    updateCreatedToast(false);
  }

  updateScrollHandleAtBottom(false);
  updateProfileTryonImage({});
  updateProfileTryOnBgImage({});
});
</script>

<style scoped lang="sass">
.dark .profile-override.layout-scroll-area
  --background: none !important
  background: none !important
.prf-bg-image
  width: 100%
  background-size: 100% 100% !important
  background-repeat: no-repeat !important
  background-attachment: fixed !important
.layout-scroll-area.stylize-layout::part(background)
  background-repeat: v-bind(overlaymode) !important
  background-image: var(--background-url) !important
  background-size: 100% auto !important
.layout-scroll-area.profile-layout::part(background)
  background-repeat: v-bind(overlaymode) !important
  background-image: var(--background-url) !important
  background-size: 100% auto !important
.layout-scroll-area.char-layout::part(background)
  background: none !important
.edit-theme-icon
  width: 25px
  height: 25px
.theme-edit-btn
  display: flex
  justify-content: end
  align-items: center
  margin-bottom: 55px

.dark .warning
  background: #ffc409 !important

.warning
  background: #ffc409
  border-radius: 0px

.sticky-warning
  position: -webkit-sticky
  position: sticky
  border-radius: 0 !important
  top: 0px
  z-index: 1

.text-color
  color: black

.layout-scroll-area.stylize-layout
  height: 100vh !important
  width: 100vw
  justify-content: center
  max-height: unset !important
  //--background: v-bind(getBackground) !important
  .layout.stylize-layout
    @media(max-width: 1185px)
      margin-right: auto !important
      margin-left: auto !important
      max-width: 70vw !important
      min-width: 70vw !important
    @media(max-width: 690px)
      max-width: 100vw !important
      min-width: 100vw !important
      padding: 70px 15px 0 15px !important
    @media(max-width: 500px)
      padding: 100px 15px 0 15px !important

.footer
  margin-top: -100px
  ::v-deep
    .footer-content
      background: #f3f3f3
.right-placeholder
  width: calc((100vw - 650px) * 0.5)
  height: calc(var(--doc-height) - var(--default-nav-bar-height))
  background: #FFF
  position: sticky
  top: 0
  align-self: flex-start
  overflow-y: hidden
  overflow-x: hidden
.layout-scroll-area
  overflow-x: hidden
  overflow-y: auto
  max-height: calc(var(--doc-height) - var(--default-nav-bar-height) - var(--default-bottom-bar-height))
  height: calc(var(--doc-height) - var(--default-nav-bar-height) - var(--default-bottom-bar-height) - var(--safe-ios-margin) - calc(var(--safe-ios-margin)/3))
  //--background: v-bind(getBackground) !important
  &.with-keyboard
    max-height: calc(var(--doc-height) - var(--default-nav-bar-height))
    height: calc(var(--doc-height) - var(--default-nav-bar-height) - var(--safe-ios-margin) - calc(var(--safe-ios-margin)/3))
  @media(max-width: 600px)
    width: 100vw !important
.colorize
  color: v-bind(defaultTextColor) !important
  ::v-deep
    .menu-btn
      .action-area
        color: v-bind(defaultTextColor) !important
    .profile-name
      color: v-bind(defaultTextColor) !important
    .user-area, .user-area-wrap
      background: v-bind(getBackground) !important
    .button-clear
      color: v-bind(defaultTextColor) !important
    .one-creation
      .name
        color: v-bind(defaultTextColor) !important
    .folder, .action-area, .footer-btn, .other-btn, .inline-button
      color: v-bind(defaultTextColor) !important
      .s1
        fill: v-bind(defaultTextColor) !important
      path
        stroke: v-bind(defaultTextColor) !important
        fill: transparent !important
      rect
        fill: transparent !important
        stroke: v-bind(defaultTextColor) !important

#main-content
  justify-content: space-between
  overflow: visible

.wrapper
  max-height: 100%
  overflow: hidden
  display: flex
  flex-direction: column

.main
  overflow-y: scroll
  margin: 0
  height: auto

.main, .layout, .wrapper
  overflow: visible !important

.footer-cover
  background-color: #ae44d4
  height: 50px
  position: absolute
  bottom: -5px
  width: 100%
  &.dark
    background-color: #11022a
    height: 80px
.layout
  width: 100%
  min-width: 650px
  max-width: 650px
  overflow: visible !important
  &:not(.w-100)
    max-width: 650px
    min-width: 650px
  &.chat-page
    padding-top: 0 !important
  ::v-deep
    .router, .page
      position: relative
    .router
      width: 100%
      max-width: 820px
      margin: auto
      z-index: 11
    .page
      display: flex
      flex-direction: column
      flex-grow: 1
      margin-bottom: auto !important
      overflow: visible !important
@media(max-width: 1184px)
  .main
    width: 100vw
  .layout
    margin: 0 !important
    min-width: 100vw !important
    max-width: 100vw !important
@media(max-width: 800px)
  .right-placeholder
    display: none !important
  .main
    width: 100%
  .layout
    margin: 0 !important
@media(max-width: 570px)
  .layout
    margin-left: 0
    width: 100% !important
    min-width: 100% !important
    padding-left: 0 !important
    padding-right: 0 !important
    &.chat-page
      padding-left: 0 !important
      padding-right: 0 !important
      padding-top: 0 !important
      @media(max-height:740px)
        min-height: 100% !important

  .footer, .footer-svg, .footer-cover
    display: none !important
  .fixed-mobile
    position: sticky !important
    top: 0
    z-index: 112
    width: 100%

.color-changed
  background: #c6c8d6 !important
.dark .color-changed
  background: #17074C !important
.customize-not-applied-margin
  margin-top: 64px
  @media(max-width:1365px)
    margin-top: 123px !important
  @media(max-width:570px)
    margin-top: 7px !important
.hide-placeholder
  display: none !important
.set-margin
  margin-top: 0px !important
.hide-vertical-scroll
  overflow-y: hidden !important
.hide-vertical-bar
  display: none !important
.customize-margin
  margin-top: 190px !important
  @media(max-width: 690px)
    margin-top: 135px !important
  @media(max-width:500px)
    margin-top: 100px !important
.customize-try-on-margin
    margin-top: 230px !important
    @media(max-width:690px)
      margin-top: 150px !important
    @media(max-width:560px)
      margin-top: 170px !important
    @media(max-width:400px)
      margin-top: 150px !important

.unauth
  min-width: 100vw !important
  max-width: 100vw !important
.lock
  overflow-y: hidden !important
</style>
